import { useState } from "react";
import {
  MotifFileUploader,
  MotifErrorMessage,
  MotifFormField,
  MotifIconButton,
  MotifIcon,
} from "@ey-xd/motif-react";
import ImagePreview from "./ImagePreview";
import "../../../assets/css/components/GalleryImages.scss";
import { navigationIcClose24px } from "@ey-xd/motif-react/assets/icons";

/**
 * Returns a unique list of files
 *
 * @param {array} prevQueue - previus list of files
 * @param {array} newFiles - new list of files
 */
const addToQueueUnique = (prevQueue = [], newFiles) => {
  const ids = new Set(prevQueue.map((prevFile) => prevFile.path));
  const merged = [
    ...prevQueue,
    ...newFiles.filter((newFile) => !ids.has(newFile.path)),
  ];
  return merged;
};
/**
 * Updates the queue with the new files dropped in
 *
 * @param {array} newFiles - new files to be added to the queue
 * @param {func} setQueue - function that updates the queue
 */
const handleDrop = (newFiles, setFileQueue, setQueue3, setQueue4) => {
  const filteredFiles = newFiles.filter(
    (file) => file.type === "image/png" || file.type === "image/jpeg"
  );
  const updatedFiles = filteredFiles.map((file) => ({
    ...file,
    name: file.name,
    uploaded: "gallery",
    size: file.size,
    previewUrl: URL.createObjectURL(file),
  }));
  setFileQueue((prevQueue) => addToQueueUnique(prevQueue, updatedFiles));
  setQueue3((prevQueue) => addToQueueUnique(prevQueue, updatedFiles)); // También actualizamos queue3 para mantener la lógica existente
  setQueue4((prevQueue) => addToQueueUnique(prevQueue, updatedFiles));
};

/**
 * Removes a single file from the queue
 *
 * @param {array} queue - current list of files to be uploaded
 * @param {func} setQueue - function that updates the queue
 * @param {Object} removedFile - the file to be removed from the queue
 */
const removeFile = (queue, setQueue4, removedFile, setFileQueue) => {
  const nextQueue = queue.filter((file) => file.url !== removedFile.url);
  setFileQueue(nextQueue);
  setQueue4(nextQueue);
};
//const [selectedFile, setSelectedFile] = useState(null);

//const handleFileChange = (event) => {
//  setSelectedFile(event.target.files[0]);
//};

/**
 * Renders each item in the queue as a MotifChip
 *
 * @param {*} queue - current list of files to be uploaded
 * @param {*} setQueue - function that updates the queue
 */
const renderQueue = (queue, setQueue, setFileQueue) =>
  queue.map((file) => (
    <div key={file.path} className="gallery-images-unique">
      <MotifIconButton
        type="button"
        className="close-cross"
        onClick={() => removeFile(queue, setQueue, file, setFileQueue)}
      >
        <MotifIcon src={navigationIcClose24px} />
      </MotifIconButton>
      <ImagePreview imageQueue={file} />
      {file.error && <MotifErrorMessage>{file.error}</MotifErrorMessage>}
    </div>
  ));
const nameLengthValidator = (file) => {
  if (file.name.length > 25) {
    return {
      code: "name-too-large",
      message: `Name is larger than ${25} characters`,
    };
  } else if (file.type !== "image/png" && file.type !== "image/jpeg") {
    return {
      code: "invalid-file-type",
      message: "Invalid file type. Only PNG and JPEG files are allowed",
    };
  } else if (file.size > 2097152) {
    return {
      code: "file-too-large",
      message: `File is larger than 2MB`,
    };
  }
  return null;
};
const GalleryImages = ({
  setFileQueue,
  error,
  errorMessage,
  isDisabled,
  pastGallery,
  isEditing,
}) => {
  const [queue3, setQueue3] = useState([]);
  const [queue4, setQueue4] = useState(pastGallery);
  return (
    <>
      <MotifFormField>
        <MotifFileUploader
          onDrop={(event) =>
            handleDrop(event, setFileQueue, setQueue3, setQueue4)
          }
          validator={nameLengthValidator}
          label="Add images"
          className="gallery-images"
          disabled={isDisabled}
          accept="image/png, image/jpeg"
          maxSize={2097152}
        >
          {isDisabled && renderQueue(pastGallery, setFileQueue)}
          {isEditing && renderQueue(queue4, setQueue4, setFileQueue)}
          {!isEditing &&
            !isDisabled &&
            renderQueue(queue3, setQueue3, setFileQueue)}
        </MotifFileUploader>
        {error && <MotifErrorMessage>{errorMessage}</MotifErrorMessage>}
      </MotifFormField>
    </>
  );
};
export default GalleryImages;
