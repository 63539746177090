import SecondaryMenu from "../../components/layout/SecondaryMenu";
import SectionFields from "../../components/form/molecules/SectionFields";
import LocationFields from "../../components/form/molecules/LocationFields";
import ManagementDetailsFields from "../../components/form/molecules/ManagementDetailsFields";
import ContactFields from "../../components/form/molecules/ContactFields";
import Modal from "../../components/Modal";
import ImagePreview from "../../components/form/molecules/ImagePreview";
import Comments from "../../components/form/molecules/Comments";
// import OrganizationModel from '../../models/OrganizationModel';

import {
  postOrganizationData,
  fetchCollectiveData,
  fetchCategoriesData,
  fetchCountriesData,
} from "../../services/api";
import { fetchCities } from "../../services/api/countriesApi";

import {
  imageUploadedFilter,
  nanoid,
  deleteEmptyFields,
  validateEmail,
} from "../../globals";
import { handleFileUpload } from "../../services/UploadImageBlob";

import LabelsContext from "../../context/LabelsContext";

import {
  MotifAccordion,
  MotifAccordionContent,
  MotifAccordionTrigger,
  MotifButton,
} from "@ey-xd/motif-react";

import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import "../../assets/css/pages/new-donation.scss";
import InvalidModal from "../../components/InvalidModal";

const NewOrganization = () => {
  const [showModal, setShowModal] = useState(false);
  const [fileQueue, setFileQueue] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [collectiveOptions, setCollectiveOptions] = useState([]);
  const [categoryListOrganizations, setCategoryListOrganizations] = useState(
    []
  );
  const [missingFields, setMissingFields] = useState([]);
  const [actionType, setActionType] = useState("");
  const [showInvalidModal, setShowInvalidModal] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(false);

  const labels = useContext(LabelsContext);

  const [formData, setFormData] = useState({
    city: null,
    country: null,
    street: "",
    latitude: "",
    longitude: "",
    contactName: "",
    contactEmail: "",
    description: "",
    collective: [],
    categories: [],
    scope: [],
    bridgeApproved: null,
    inhouse: null,
    cif: "",
    phoneNumber: "",
    imageCover: null,
    image: {},
    organizationName: "",
    comments: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const collectiveData = await fetchCollectiveData();
        const collectiveOptions = Array.isArray(collectiveData.collectives)
          ? collectiveData.collectives.map((collective) => ({
              value: collective.id,
              label: collective.name,
            }))
          : [];
        setCollectiveOptions(collectiveOptions);
        const categoriesData = await fetchCategoriesData();
        const categories = Array.isArray(categoriesData.categories)
          ? categoriesData.categories.map((category) => ({
              value: category.id,
              label: category.name,
            }))
          : [];
        setCategoryListOrganizations(categories);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };
    fetchCountriesData()
      .then((data) => {
        const countries = Array.isArray(data)
          ? data.map((country) => ({
              value: country.countryName,
              label: country.countryName,
              key: country.countryKey,
            }))
          : [];
        setCountryOptions(countries);
      })
      .catch((error) => console.error("Error al cargar países:", error));
    fetchData();
  }, []);

  const handleFieldChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleFormSubmit = async (drafted) => {
    setisFormSubmited(true);
    if (isFormValid()) {
      setIsDisabledButton(true);
      const id = nanoid();
      const coordinates =
        !formData.latitude ||
        !formData.longitude ||
        formData.latitude === "" ||
        formData.longitude === ""
          ? null
          : {
              latitude: parseFloat(formData.latitude),
              longitude: parseFloat(formData.longitude),
            };
      const address = {
        city: formData.city,
        country: formData.country,
        street: formData.street,
      };
      const contact = {
        name: formData.contactName,
        email: formData.contactEmail,
        phone: formData.phoneNumber,
      };
      const name = formData.organizationName;
      const strategicAxis = formData.categories || null;
      formData.inhouse =
        formData.inhouse === "" || formData.inhouse === null
          ? null
          : formData.inhouse === "Yes";
      let copyFormData = { ...formData };
      delete copyFormData.latitude;
      delete copyFormData.longitude;
      delete copyFormData.contactEmail;
      delete copyFormData.contactName;
      delete copyFormData.phoneNumber;
      delete copyFormData.city;
      delete copyFormData.country;
      delete copyFormData.street;
      delete copyFormData.organizationName;
      delete copyFormData.categories;
      const submitData = {
        ...copyFormData,
        contact,
        location: {
          address,
          coordinates,
        },
        id,
        strategicAxis,
        logo: fileQueue
          ? imageUploadedFilter(fileQueue, id, "organization")
          : null,
        name,
        isDraft: drafted,
        comments: formData.comments === "<p><br></p>" ? "" : formData.comments,
      };
      handleFileUpload(fileQueue, submitData.id, "organization");
      await postOrganizationData(deleteEmptyFields(submitData));
      navigate("/organizations/all-organizations");
      setIsDisabledButton(false);
    } else {
      setShowInvalidModal(true);
      setIsDisabledButton(false);
    }
  };

  const isFormValid = () => {
    const requiredFields = [
      "organizationName",
      "description",
      "contactName",
      "contactEmail",
      "phoneNumber",
      "city",
      "country",
      "street",
    ];
    const newMissingFields = [];
    requiredFields.forEach((field) => {
      if (
        !formData[field] ||
        (field === "description" && formData[field] === "<p><br></p>")
      ) {
        newMissingFields.push(field);
      }
    });
    if (!validateEmail(formData.contactEmail)) {
      newMissingFields.push("contactEmail");
      setMissingFields(newMissingFields);
      return false;
    }
    setMissingFields(newMissingFields);
    return newMissingFields.length === 0;
  };
  const [isFormSubmited, setisFormSubmited] = useState(false);
  const navigate = useNavigate();

  const openWarningModal = () => {
    setActionType("save");
    setShowModal(true);
  };

  const closeWarningModal = () => {
    setShowModal(false);
  };
  const closeInvalidModal = () => {
    setShowModal(false);
    setShowInvalidModal(false);
  };
  const handleCancelClick = () => {
    setActionType("cancel");
    setShowModal(true);
  };
  const handleDiscardChanges = () => {
    window.scrollTo(0, 0);
    setShowModal(false);
    navigate("/");
  };

  const handleNavigateHome = () => {
    navigate("/organizations/all-organizations");
  };

  const handleCountryChange = (countryCode) => {
    fetchCities(countryCode)
      .then((data) => {
        setCityOptions(
          data.geonames.map((city) => ({ value: city.name, label: city.name }))
        );
      })
      .catch((error) =>
        console.error(
          `Error al obtener ciudades para el país ${countryCode}:`,
          error
        )
      );
  };

  const formFieldConfig = {
    latitude: { required: false },
    longitude: { required: false },
    organizationName: { required: true },
    city: { required: true },
    country: { required: true },
    street: { required: true },
    bridgeApproved: { required: false },
    image: { required: false },
  };

  return (
    <>
      <SecondaryMenu
        title={labels.console_create_new_organization}
        onNavigate={handleNavigateHome}
        editing={true}
        sendFormDraft={() => handleFormSubmit(true)}
        pageType="organizations"
      />
      <section className="motif-col-lg-12 section-page">
        <div className={fileQueue[0] ? "container-test" : null}>
          <div>
            <SectionFields
              formData={formData}
              setFormData={setFormData}
              isFormSubmited={isFormSubmited}
              setFileQueue={setFileQueue}
              imageLabel={labels.console_logo}
              descriptionLabel={labels.console_description_org}
              formFieldConfig={formFieldConfig}
              mode={"create"}
              fieldVisibility={{
                organizationName: true,
                cif: true,
                description: true,
                categories: true,
                scope: true,
                imageCover: true,
                image: true,
              }}
            />
          </div>
          {fileQueue[0] && (
            <img src={fileQueue[0]?.previewUrl} alt="" className="image-test" />
          )}
        </div>
        <MotifAccordion alignIconRight useChevronIcon className="accordion">
          <MotifAccordionTrigger className="accordion-title">
            {labels.console_location}
          </MotifAccordionTrigger>
          <MotifAccordionContent className="accordion-box">
            <LocationFields
              onFieldChange={handleFieldChange}
              formData={formData}
              setFormData={setFormData}
              isFormSubmited={isFormSubmited}
              countryOptions={countryOptions}
              cityOptions={cityOptions}
              onCountryChange={handleCountryChange}
              setCityOptions={setCityOptions}
              formFieldConfig={formFieldConfig}
              fieldVisibility={{
                city: true,
                country: true,
                latitude: true,
                longitude: true,
                street: true,
              }}
            />
          </MotifAccordionContent>
        </MotifAccordion>
        <MotifAccordion alignIconRight useChevronIcon className="accordion">
          <MotifAccordionTrigger className="accordion-title">
            {labels.console_management_details}
          </MotifAccordionTrigger>
          <MotifAccordionContent className="accordion-box">
            <ManagementDetailsFields
              onFieldChange={handleFieldChange}
              formData={formData}
              setFormData={setFormData}
              isFormSubmited={isFormSubmited}
              collectiveOptions={collectiveOptions}
              categoryListOrganizations={categoryListOrganizations}
              fieldVisibility={{
                collective: true,
                categories: true,
                scope: true,
                bridgeApproved: true,
                inhouse: true,
              }}
            />
          </MotifAccordionContent>
        </MotifAccordion>
        <MotifAccordion alignIconRight useChevronIcon className="accordion">
          <MotifAccordionTrigger className="accordion-title">
            {labels.console_contact}
          </MotifAccordionTrigger>
          <MotifAccordionContent className="accordion-box">
            <ContactFields
              onFieldChange={handleFieldChange}
              formData={formData}
              setFormData={setFormData}
              isFormSubmited={isFormSubmited}
              labels={labels}
              fieldVisibility={{
                contactName: true,
                contactEmail: true,
                phoneNumber: true,
              }}
            />
          </MotifAccordionContent>
        </MotifAccordion>
        <MotifAccordion alignIconRight useChevronIcon className="accordion">
          <MotifAccordionTrigger className="accordion-title">
            {labels.console_comments}
          </MotifAccordionTrigger>
          <MotifAccordionContent className="accordion-box">
            <Comments
              onFieldChange={handleFieldChange}
              formData={formData}
              setFormData={setFormData}
              isFormSubmited={isFormSubmited}
            />
          </MotifAccordionContent>
        </MotifAccordion>
        <SectionFields
          formData={formData}
          setFormData={setFormData}
          isFormSubmited={isFormSubmited}
          setFileQueue={setFileQueue}
          fieldVisibility={{
            inhouse: true,
          }}
        />

        <div className="activity-buttons">
          <MotifButton
            variant="secondary"
            onClick={handleCancelClick}
            className="activity-buttons-secondary"
          >
            {labels.console_cancel}
          </MotifButton>
          <MotifButton
            onClick={openWarningModal}
            className="activity-buttons-primary"
          >
            {labels.console_save}
          </MotifButton>
        </div>
      </section>
      <section className="motif-col-lg-5">
        <ImagePreview imageQueue={fileQueue} />
      </section>

      <Modal
        show={showModal}
        onClose={closeWarningModal}
        title={
          actionType === "save"
            ? labels.console_changes_saved
            : labels.console_unsavedChanges
        }
        body={
          actionType === "save"
            ? labels.console_confirm_publish_organization
            : labels.console_leavePage
        }
        primaryButtonText={
          actionType === "save"
            ? labels.console_publish
            : labels.console_keepEditing
        }
        onPrimaryButtonClick={
          actionType === "save"
            ? () => handleFormSubmit(false)
            : closeWarningModal
        }
        secondaryButtonText={
          actionType === "save"
            ? labels.console_cancel
            : labels.console_discardChanges
        }
        onSecondaryButtonClick={handleDiscardChanges}
        disabled={isDisabledButton}
      />
      <InvalidModal
        show={showInvalidModal}
        onClose={closeInvalidModal}
        title={labels.console_title_invalidForm}
        body={labels.console_body_invalidForm}
        primaryButtonText={labels.console_close}
        onPrimaryButtonClick={closeInvalidModal}
        invalidFieldsMessage={missingFields.join(", ")}
      />
    </>
  );
};

export default NewOrganization;
