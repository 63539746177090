import UploadFile from "./UploadFile";
import TextInput from "./TextInput";
import SelectInput from "./SelectInput";
import NumericInput from "./NumericInput";
import MultipleSelect from "./MultipleSelect";
import {
  MotifDatePicker,
  MotifErrorMessage,
  MotifFormField,
  MotifLabel,
  MotifToggleSwitch,
} from "@ey-xd/motif-react";
import GalleryImages from "./GalleryImages";
import { useContext, useState } from "react";
import RichTextEditorField from "./RichTextEditorField";
import LabelsContext from "../../../context/LabelsContext";

const SectionFields = ({
  formData,
  setFormData,
  isFormSubmited,
  setFileQueue,
  setFileQueueGallery,
  collectiveOptions,
  organizationId,
  categoryOptions,
  donationsOptions,
  actionsOptions,
  countryOptions,
  imageLabel,
  descriptionLabel,
  formFieldConfig,
  fieldVisibility = {
    image: true,
    url: true,
    collective: true,
    categoryName: true,
    donationType: true,
    totalAmountToBeDonated: true,
    minimumAmountToBeRecolected: true,
    expensesAssociatedWithEvent: true,
    organizationPurpose: true,
    description: true,
    title: true,
    subtitle: true,
    organizationName: true,
    organizationId: true,
    cif: true,
    titleLanding: true,
    urlLink: true,
  },
  isDisabled,
  pastGallery,
  isEditing,
  mode,
}) => {
  const [disabledFields, setDisableFields] = useState({
    actions: false,
    donations: false,
  });
  const handleChange = (fieldName, value) => {
    if (fieldName === "actionId" || fieldName === "donationId") {
      if (value === "") value = null;
      if (fieldName === "actionId" && value !== null) {
        setDisableFields({ actions: false, donations: true });
      } else if (fieldName === "donationId" && value !== null) {
        setDisableFields({ actions: true, donations: false });
      } else {
        setDisableFields({ actions: false, donations: false });
      }
    }
    if (fieldName === "organizationId" && typeof value !== "object") {
      // Find the corresponding organization object based on the name
      const matchedOrganization = organizationId.find(
        (org) => org.name === value
      );
      if (matchedOrganization) {
        value = matchedOrganization.id; // Set the value to the ID of the matched organization
      }
    }
    if (fieldName === "countryValid") {
      const selectedCountry = countryOptions.find(
        (option) => option.value === value
      );
      value = selectedCountry?.label || "";
    }
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: Array.isArray(prevData[fieldName])
        ? [...prevData[fieldName], value]
        : value,
    }));
  };
  const labels = useContext(LabelsContext);
  const handleCollectiveChange = (selectedCollective) => {
    setFormData((prev) => ({ ...prev, collectiveIdList: selectedCollective }));
  };
  const options = [
    { value: "apple", label: "Apple" },
    { value: "orange", label: "Orange" },
    { value: "grape", label: "Grape" },
  ];
  return (
    <>
      {fieldVisibility.titleLanding && (
        <TextInput
          label={labels.console_title_landing}
          placeholder={isDisabled ? false : "Enter title"}
          value={formData.titleLanding}
          onChange={(val) => handleChange("titleLanding", val)}
          required={true}
          error={!formData.titleLanding && isFormSubmited}
          errorMessage="Oops, please enter a title!"
          disabled={isDisabled}
        />
      )}
      {fieldVisibility.title && (
        <TextInput
          label={labels.console_table_title}
          placeholder={isDisabled ? false : "Enter title"}
          value={formData.title}
          onChange={(val) => handleChange("title", val)}
          error={!formData.title && isFormSubmited}
          errorMessage="Oops, please enter a title!"
          required={true}
          disabled={isDisabled}
        />
      )}

      {fieldVisibility.subtitle && (
        <TextInput
          label={labels.console_subtitle}
          placeholder="Enter subtitle"
          value={formData.subTitle}
          onChange={(val) => handleChange("subTitle", val)}
          disabled={isDisabled}
        />
      )}

      {fieldVisibility.orgUrl && (
        <TextInput
          label={labels.console_organization_url}
          placeholder={isDisabled ? false : "Enter organization url"}
          value={formData.orgUrl}
          onChange={(val) => handleChange("orgUrl", val)}
          error={!formData.orgUrl && isFormSubmited}
          errorMessage="Oops, please please enter a organization url!"
          disabled={isDisabled}
        />
      )}

      {fieldVisibility.organizationId && (
        <SelectInput
          label={labels.console_list_organization}
          value={formData.organizationId}
          options={
            Array.isArray(organizationId)
              ? organizationId.map((org) => ({
                  value: org.id,
                  label: org.name,
                }))
              : []
          }
          error={!formData.organizationId && isFormSubmited}
          errorMessage="Oops, please please enter a organization name!"
          onChange={(val) => handleChange("organizationId", val)}
          required={true}
          disabled={isDisabled}
          country={true}
          filter
        />
      )}

      {fieldVisibility.organizationName && (
        <TextInput
          label={labels.console_table_organization_name}
          value={formData.organizationName}
          onChange={(val) => handleChange("organizationName", val)}
          error={
            formFieldConfig.organizationName.required &&
            !formData.organizationName &&
            isFormSubmited
          }
          errorMessage="Oops, please please enter a organization name!"
          required={formFieldConfig.organizationName.required}
          disabled={isDisabled}
        />
      )}

      {fieldVisibility.cif && (
        <TextInput
          label={labels.console_table_cif}
          placeholder="Enter CIF"
          value={formData.cif}
          onChange={(val) => handleChange("cif", val)}
          disabled={isDisabled}
        />
      )}

      {fieldVisibility.image && (
        <div>
          <label className="input-label">
            {imageLabel}
            {formFieldConfig.image.required && (
              <span className="input-label-required">*</span>
            )}
          </label>
          <UploadFile
            setFileQueue={setFileQueue}
            errorMessage="Oops, please upload an image!"
            isDisabled={isDisabled}
            mode={mode}
          />
        </div>
      )}

      {fieldVisibility.url && (
        <TextInput
          label={labels.console_video}
          placeholder="Enter link"
          value={formData.url}
          onChange={(val) => handleChange("url", val)}
          disabled={isDisabled}
        />
      )}

      {fieldVisibility.urlLink && (
        <TextInput
          label={labels.console_link}
          placeholder="Enter link"
          value={formData.urlLink}
          onChange={(val) => handleChange("urlLink", val)}
          disabled={isDisabled}
        />
      )}

      {fieldVisibility.categoryName && (
        <SelectInput
          label={labels.console_category}
          value={formData.categoryName}
          onChange={(val) => handleChange("categoryName", val)}
          options={categoryOptions}
          disabled={isDisabled}
          filter
        />
      )}

      {fieldVisibility.categoryId && (
        <SelectInput
          label={labels.console_category}
          value={formData.categoryId}
          onChange={(val) => handleChange("categoryId", val)}
          options={categoryOptions}
          required
          error={!formData.categoryId && isFormSubmited}
          errorMessage="Oops, please select a category!"
          disabled={isDisabled}
          country={true}
          filter
        />
      )}

      {fieldVisibility.collectiveIdList && (
        <MultipleSelect
          label={labels.console_collective}
          value={formData.collectiveIdList}
          onChange={handleCollectiveChange}
          options={Array.isArray(collectiveOptions) ? collectiveOptions : []}
          disabled={isDisabled}
        />
      )}

      {fieldVisibility.creationDate && (
        <MotifFormField className="form-field">
          <MotifLabel className="input-label">
            {labels.console_creation_date}:{" "}
            <span className="input-label-required">*</span>
          </MotifLabel>
          <MotifDatePicker
            value={formData.creationDate}
            onChange={(value) => handleChange("creationDate", value)}
            format="dd-MM-yyyy"
            yearPlaceholder="YYYY"
            dayPlaceholder="DD"
            monthPlaceholder="MM"
            minDate={formData.creationDate ? formData.creationDate : new Date()}
            disabled={isDisabled}
          />
          {isFormSubmited && !formData.creationDate && (
            <MotifErrorMessage>
              Oops, please pick a start date!
            </MotifErrorMessage>
          )}
        </MotifFormField>
      )}

      {fieldVisibility.skills && (
        <TextInput
          label={labels.console_skills}
          placeholder="Skills"
          value={formData.skills}
          onChange={(val) => handleChange("skills", val)}
          required={false}
          disabled={isDisabled}
        />
      )}

      {fieldVisibility.donationType && (
        <SelectInput
          label={labels.console_donation_type}
          value={formData.donationType}
          onChange={(val) => handleChange("donationType", val)}
          options={options}
          required
          error={!formData.donationType && isFormSubmited}
          errorMessage="Oops, please select a donation!"
          filter
        />
      )}

      {fieldVisibility.totalAmountToBeDonated && (
        <NumericInput
          label={labels.console_amount_tobe_donated}
          placeholder="Enter the total amount to be donated"
          value={formData.totalAmountToBeDonated}
          onChange={(val) => handleChange("totalAmountToBeDonated", val)}
          required={true}
          error={!formData.totalAmountToBeDonated && isFormSubmited}
          errorMessage="This field is required."
          validateNonNegative={true}
        />
      )}

      {fieldVisibility.minimumAmountToBeRecolected && (
        <NumericInput
          label={labels.console_minimum_amount}
          placeholder="Enter the minimum amount no money to be recolected"
          value={formData.minimumAmountToBeRecolected}
          onChange={(val) => handleChange("minimumAmountToBeRecolected", val)}
          required={true}
          error={!formData.minimumAmountToBeRecolected && isFormSubmited}
          errorMessage="This field is required."
          validateNonNegative={true}
        />
      )}

      {fieldVisibility.expensesAssociatedWithEvent && (
        <NumericInput
          label={labels.console_expenses_asociated}
          placeholder="Enter the expenses associated with this event"
          value={formData.expensesAssociatedWithEvent}
          onChange={(val) => handleChange("expensesAssociatedWithEvent", val)}
          required={true}
          error={!formData.expensesAssociatedWithEvent && isFormSubmited}
          errorMessage="This field is required."
          validateNonNegative={true}
        />
      )}
      {fieldVisibility.country && (
        <SelectInput
          label={labels.console_country}
          value={formData.country}
          onChange={(val) => handleChange("country", val)}
          options={countryOptions}
          required
          error={!formData.country && isFormSubmited}
          errorMessage="Oops, please select a country!"
          disabled={isDisabled}
          country={true}
          filter
        />
      )}

      {fieldVisibility.description && (
        <RichTextEditorField
          label={descriptionLabel}
          value={formData.description}
          onChange={(val) => handleChange("description", val)}
          error={
            (!formData.description && isFormSubmited) ||
            formData.description === "<p><br></p>"
          }
          errorMessage="Oops, description is required!"
          required={true}
          disabled={isDisabled}
        />
      )}
      {fieldVisibility.descriptionNotif && (
        <TextInput
          label={descriptionLabel}
          value={formData.description}
          onChange={(val) => handleChange("description", val)}
          error={!formData.description && isFormSubmited}
          errorMessage="Oops, description is required!"
          required={true}
          disabled={isDisabled}
        />
      )}
      {fieldVisibility.gallery && (
        <div>
          <label className="input-label">
            {labels.console_gallery}{" "}
            <span className="input-label-required"></span>
          </label>
          <GalleryImages
            setFileQueue={setFileQueueGallery}
            pastGallery={pastGallery}
            isEditing={isEditing}
            error={!formData.gallery && isFormSubmited}
            errorMessage="Oops, please upload an image or video!"
            isDisabled={isDisabled}
          />
        </div>
      )}
      {fieldVisibility.countryValid && (
        <SelectInput
          label={labels.console_country}
          value={formData.countryValid}
          onChange={(val) => handleChange("countryValid", val)}
          options={countryOptions}
          required
          error={!formData.countryValid && isFormSubmited}
          errorMessage="Oops, please select a country!"
          disabled={isDisabled}
          country={true}
          filter
        />
      )}
      {fieldVisibility.actionsNotif && (
        <SelectInput
          label={labels.console_actions}
          value={formData.actions}
          onChange={(val) => handleChange("actionId", val)}
          options={actionsOptions}
          required={false}
          disabled={disabledFields.actions}
          filter
        />
      )}
      {fieldVisibility.donationsNotif && (
        <SelectInput
          label={labels.console_donations}
          value={formData.donations}
          onChange={(val) => handleChange("donationId", val)}
          options={donationsOptions}
          required={false}
          disabled={disabledFields.donations}
          filter
        />
      )}
      {fieldVisibility.isReminder && (
        <MotifFormField>
          <MotifLabel className="input-label">
            {labels.console_reminder}
          </MotifLabel>
          <MotifToggleSwitch
            checked={formData.isReminder}
            onChange={(event) => {
              handleChange("isReminder", event.target.checked);
            }}
            id="isReminder"
            onLabel="Yes"
            offLabel="No"
          />
        </MotifFormField>
      )}
    </>
  );
};

export default SectionFields;
