import labelsData from "./labels";
import { getToken } from "../globals";

const API_URL = process.env.REACT_APP_GIVEYRS_API_BASE_URL;

export const getLabels = async (language) => {
  try {
    const token = getToken();

    const response = await fetch(`${API_URL}/labels`, {
      headers: {
        "Accept-Language": language,
        token: await token,
      },
    });
    const text = await response.text();
    const data = JSON.parse(text);
    return data;
  } catch (error) {
    console.error("Error fetching labels:", error);
    throw error;
  }
};

export const fetchActions = async (draft) => {
  try {
    const token = getToken();

    const response = await fetch(
      `${API_URL}/actions?draft=${draft}&onlyActive=false`,
      {
        headers: {
          "Accept-Language": "en",
          token: await token,
        },
      }
    );
    const data = await response.json();
    return data.actions;
  } catch (error) {
    console.error("Error fetching the actions data", error);
    throw error;
  }
};

export const fetchActionDetails = async (actionId) => {
  try {
    const token = getToken();

    const response = await fetch(
      `${API_URL}/actions?actionIds=${actionId}&onlyActive=false`,
      {
        headers: {
          "Accept-Language": "en",
          token: await token,
        },
      }
    );
    const data = await response.json();
    return data.actions;
  } catch (error) {
    console.error("Error fetching the actions data", error);
    throw error;
  }
};

export const fetchOrganizationsData = async (draft) => {
  try {
    const token = getToken();

    const response = await fetch(`${API_URL}/organization?draft=${draft}`, {
      headers: {
        "Accept-Language": "en",
        token: await token,
      },
    });
    const data = await response.json();
    return data.organizations;
  } catch (error) {
    console.error("Error fetching the organizations data", error);
    throw error;
  }
};

export const fetchLandingsData = async (draft) => {
  try {
    const token = getToken();

    const response = await fetch(`${API_URL}/landings?draft=${draft}`, {
      headers: {
        "Accept-Language": "en",
        token: await token,
      },
    });
    const data = await response.json();
    return data.landings;
  } catch (error) {
    console.error("Error fetching the landings data", error);
    throw error;
  }
};
export const fetchDonationsData = async (draft) => {
  try {
    const token = getToken();

    const response = await fetch(
      `${API_URL}/donations?draft=${draft}&onlyActive=false`,
      {
        headers: {
          "Accept-Language": "en",
          token: await token,
        },
      }
    );
    const data = await response.json();
    return data.donations;
  } catch (error) {
    console.error("Error fetching the landings data", error);
    throw error;
  }
};

export const fetchEventData = async (eventId) => {
  try {
    const token = getToken();

    const response = await fetch(`${API_URL}/events?eventIds=${eventId}`, {
      headers: {
        "Accept-Language": "en",
        token: await token,
      },
    });
    const data = await response.json();
    return data.events;
  } catch (error) {
    console.error("Error fetching the landings data", error);
    throw error;
  }
};

export const fetchProbonosData = async (draft) => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/probonuses?draft=${draft}`, {
      headers: {
        "Accept-Language": "en",
        token: await token,
      },
    });
    const data = await response.json();
    return data.bonuses;
  } catch (error) {
    console.error("Error fetching the landings data", error);
    throw error;
  }
};

export const postAction = async (actionData) => {
  try {
    const token = getToken();
    const options = {
      method: "POST",
      headers: {
        "Accept-Language": "en",
        "Content-Type": "application/json",
        token: await token,
      },
      body: JSON.stringify(actionData),
    };
    const response = await fetch(`${API_URL}/action`, options);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return;
  } catch (error) {
    console.error("Error post the events data", error);
    throw error;
  }
};

export const postProbonoData = async (probonoData) => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/probonus`, {
      method: "POST",
      headers: {
        "Accept-Language": "en",
        "Content-Type": "application/json",
        token: await token,
      },
      body: JSON.stringify(probonoData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return;
  } catch (error) {
    console.error("Error posting probono data:", error);
  }
};

export const postOrganizationData = async (organizationData) => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/organization`, {
      method: "POST",
      headers: {
        "Accept-Language": "en",
        "Content-Type": "application/json",
        token: await token,
      },
      body: JSON.stringify(organizationData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return;
  } catch (error) {
    console.error("Error posting organization data:", error);
    throw error;
  }
};

// para hardcodear backend con los edit pages

export const updateEventData = async (eventId, updatedData) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ success: true });
    }, 1000);
  });
};

export const fetchAdminUsers = async () => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/adminUsers`, {
      headers: {
        "Accept-Language": "en",
        token: await token,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching the landings data", error);
    throw error;
  }
};
export const fetchAllUsers = async () => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/allUsers`, {
      headers: {
        "Accept-Language": "en",
        token: await token,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching the landings data", error);
    throw error;
  }
};

export const findUser = async (encodedEmail) => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/findUser?mail=${encodedEmail}`, {
      headers: {
        "Accept-Language": "en",
        token: await token,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching the landings data", error);
    throw error;
  }
};

export const fetchCollectiveData = async () => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/collectives`, {
      headers: {
        "Accept-Language": "en",
        token: await token,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching the landings data", error);
    throw error;
  }
};

export const fetchCategoriesData = async () => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/categories`, {
      headers: {
        "Accept-Language": "en",
        token: await token,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching the landings data", error);
    throw error;
  }
};

export const postLandingsData = async (landingData) => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/landing`, {
      method: "POST",
      headers: {
        "Accept-Language": "en",
        "Content-Type": "application/json",
        token: await token,
      },
      body: JSON.stringify(landingData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return;
  } catch (error) {
    console.error("Error posting organization data:", error);
    throw error;
  }
};

export const postDonationData = async (donationData) => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/donation`, {
      method: "POST",
      headers: {
        "Accept-Language": "en",
        "Content-Type": "application/json",
        token: await token,
      },
      body: JSON.stringify(donationData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return;
  } catch (error) {
    console.error("Error posting organization data:", error);
    throw error;
  }
};
export const postQuestion = async (questionData) => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/question`, {
      method: "POST",
      headers: {
        "Accept-Language": "en",
        "Content-Type": "application/json",
        token: await token,
      },
      body: JSON.stringify(questionData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return;
  } catch (error) {
    console.error("Error posting organization data:", error);
    throw error;
  }
};

export const postLabels = async () => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/labels`, {
      method: "POST",
      headers: {
        "Accept-Language": "en",
        "Content-Type": "application/json",
        token: await token,
      },
      body: JSON.stringify(labelsData),
    });

    if (!response.ok) {
      throw new Error("Error al postear los labels");
    }

    return;
  } catch (error) {
    console.error("Error en postLabels:", error);
    throw error;
  }
};
export const postRolePromote = async (role, userId) => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/promote/${role}/${userId}`, {
      method: "POST",
      headers: {
        "Accept-Language": "en",
        "Content-Type": "application/json",
        token: await token,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return;
  } catch (error) {
    console.error("Error posting organization data:", error);
    throw error;
  }
};

export const postUserComment = async (comment) => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/user/update`, {
      method: "POST",
      headers: {
        "Accept-Language": "en",
        "Content-Type": "application/json",
        token: await token,
      },
      body: JSON.stringify(comment),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return;
  } catch (error) {
    console.error("Error posting organization data:", error);
    throw error;
  }
};

export const postNotification = async (notification) => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/pushGeneralNotification`, {
      method: "POST",
      headers: {
        "Accept-Language": "en",
        "Content-Type": "application/json",
        token: await token,
      },
      body: JSON.stringify(notification),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return;
  } catch (error) {
    console.error("Error posting notification:", error);
    throw error;
  }
};
export const fetchQuestionsData = async () => {
  try {
    const token = getToken();
    const response = await fetch(
      `${API_URL}/questions?questionType=RECYCLING`,
      {
        headers: {
          "Accept-Language": "en",
          token: await token,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching the landings data", error);
    throw error;
  }
};
export const fetchCountriesData = async () => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/countries`, {
      headers: {
        "Accept-Language": "en",
        token: await token,
      },
    });
    const data = await response.json();
    return data.countryList;
  } catch (error) {
    console.error("Error fetching the country data", error);
    throw error;
  }
};
export const fetchOrganizationIdData = async (organizationId) => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/organization/${organizationId}`, {
      headers: {
        "Accept-Language": "en",
        token: await token,
      },
    });
    const data = await response.json();
    return data.name;
  } catch (error) {
    console.error("Error fetching the organizations data", error);
    throw error;
  }
};

export const fetchQR = async (actionId) => {
  try {
    const token = getToken();
    const requestUrl = `${API_URL}/image/qr?actionId=${actionId}`;
    console.log("Fetching QR code from URL:", requestUrl);
    const response = await fetch(`${API_URL}/image/qr?actionId=${actionId}`, {
      headers: {
        "Accept-Language": "en",
        token: await token,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching the organizations data", error);
    throw error;
  }
};
export const fetchVolunteersActionList = async (actionId) => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/users/subscribed/${actionId}`, {
      headers: {
        "Accept-Language": "en",
        token: await token,
      },
    });
    const data = await response.json();
    return data.users;
  } catch (error) {
    console.error("Error fetching the volunteers action data list", error);
    throw error;
  }
};

export const postDeclineUserAction = async (userId, actionId) => {
  try {
    const token = getToken();
    const response = await fetch(
      `${API_URL}/decline/${actionId}?userId=${userId}`,
      {
        method: "POST",
        headers: {
          "Accept-Language": "en",
          "Content-Type": "application/json",
          token: await token,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return;
  } catch (error) {
    console.error("Error posting organization data:", error);
    throw error;
  }
};

export const signInConsole = async () => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/signIn`, {
      method: "POST",
      headers: {
        "Accept-Language": "en",
        "Content-Type": "application/json",
        token: await token,
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return;
  } catch (error) {
    console.error("Error signing in", error);
  }
};

export const fetchUserData = async () => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/me`, {
      headers: {
        "Accept-Language": "en",
        token: await token,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching the user data", error);
  }
};
export const deleteLanding = async (landingId) => {
  try {
    const token = getToken();
    const response = await fetch(`${API_URL}/landing/${landingId}`, {
      method: "DELETE",
      headers: {
        "Accept-Language": "es",
        token: await token,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response;
  } catch (error) {
    console.error("Error deleting landing", error);
    throw error;
  }
};
