import { useState, useEffect } from "react";
import {
  MotifFileUploader,
  MotifFileUploaderItem,
  MotifIcon,
  MotifErrorMessage,
  MotifFormField,
} from "@ey-xd/motif-react";
import { actionIcDescription24px } from "@ey-xd/motif-react/assets/icons";
import "../../../assets/css/components/UploadFile.scss";

/**
 * Compresses an image file using Canvas.
 *
 * @param {File} file - The file to compress.
 * @param {number} maxWidth - The maximum width of the compressed image.
 * @param {number} maxHeight - The maximum height of the compressed image.
 * @param {number} quality - The quality of the compressed image (0 to 1).
 * @returns {Promise<File>} - A promise that resolves to the compressed file.
 */
const compressImage = (file, maxWidth, maxHeight, quality) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);

    image.onload = () => {
      let width = image.width;
      let height = image.height;

      if (width > maxWidth || height > maxHeight) {
        if (width > height) {
          height *= maxWidth / width;
          width = maxWidth;
        } else {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          resolve(new File([blob], file.name, { type: file.type }));
        },
        file.type,
        quality
      );
    };

    image.onerror = (error) => reject(error);
  });
};

/**
 * Returns a unique list of files by removing duplicates.
 *
 * @param {array} prevQueue - previous list of files
 * @param {array} newFiles - new list of files
 */
const addToQueueUnique = (prevQueue, newFiles) => {
  const ids = new Set(prevQueue.map((prevFile) => prevFile.path));
  return [
    ...prevQueue,
    ...newFiles.filter((newFile) => !ids.has(newFile.path)),
  ];
};

/**
 * Removes a single file from the queue.
 *
 * @param {array} queue - current list of files to be uploaded
 * @param {func} setQueue - function that updates the queue
 * @param {Object} removedFile - the file to be removed from the queue
 */
const removeFile = (queue, setQueue, removedFile) => {
  const nextQueue = queue.filter((file) => file.path !== removedFile.path);
  setQueue(nextQueue);
};

/**
 * Renders each item in the queue as a MotifFileUploaderItem.
 *
 * @param {*} queue - current list of files to be uploaded
 * @param {*} setQueue - function that updates the queue
 */
const renderQueue = (queue, setQueue) =>
  queue.map((file) => (
    <div key={file.path}>
      <MotifFileUploaderItem
        fileIcon={<MotifIcon src={actionIcDescription24px} />}
        fileName={file.name}
        fileSize={file.size}
        error={file.error && true}
        onRemove={() => removeFile(queue, setQueue, file)}
        removable
      />
      {file.error && <MotifErrorMessage>{file.error}</MotifErrorMessage>}
    </div>
  ));

const nameLengthValidator = (file) => {
  if (file.name.length > 25) {
    return {
      code: "name-too-large",
      message: `Name is larger than 25 characters`,
    };
  } else if (file.type !== "image/png" && file.type !== "image/jpeg") {
    return {
      code: "invalid-file-type",
      message: "Only PNG and JPEG are allowed",
    };
  } else if (file.size > 2097152) {
    return { code: "file-too-large", message: "File is larger than 2MB" };
  }
  return null;
};

const UploadFile = ({
  setFileQueue,
  error,
  errorMessage,
  isDisabled,
  mode,
  initialFile,
}) => {
  const [queue3, setQueue3] = useState([]);

  useEffect(() => {
    if (mode === "edit" && initialFile) {
      setQueue3([initialFile]);
    }
  }, [mode, initialFile]);

  const handleFileDrop = async (newFiles) => {
    const filteredFiles = newFiles.filter(
      (file) => file.type === "image/png" || file.type === "image/jpeg"
    );

    const compressedFiles = await Promise.all(
      filteredFiles.map((file) => compressImage(file, 800, 800, 0.7))
    );

    const updatedFiles = compressedFiles.map((file) => ({
      ...file,
      name: file.name,
      path: file.path,
      size: file.size,
      error: file.error,
      uploaded: "image",
      previewUrl: URL.createObjectURL(file),
    }));

    if (mode === "create") {
      const uniqueFiles = addToQueueUnique([], updatedFiles).slice(0, 1);
      setQueue3(uniqueFiles);
      setFileQueue(uniqueFiles);
    } else {
      const uniqueFiles = addToQueueUnique([], updatedFiles);
      setQueue3(uniqueFiles);
      setFileQueue(uniqueFiles);
    }
  };

  return (
    <MotifFormField>
      <MotifFileUploader
        onDrop={(event) => handleFileDrop(event)}
        validator={nameLengthValidator}
        label="Suelte los archivos aquí o haga clic para cargarlos"
        disabled={isDisabled}
        accept="image/png, image/jpeg"
        maxSize={2097152}
      >
        {renderQueue(queue3, setQueue3)}
      </MotifFileUploader>
      {error && <MotifErrorMessage>{errorMessage}</MotifErrorMessage>}
    </MotifFormField>
  );
};

export default UploadFile;
