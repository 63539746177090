import SecondaryMenu from "../../components/layout/SecondaryMenu";
import SectionFields from "../../components/form/molecules/SectionFields";
import DateTimeForm from "../../components/form/molecules/DateTimeForm";
import ManagementDetailsFields from "../../components/form/molecules/ManagementDetailsFields";
import ActiveInactiveSwitch from "../../components/form/molecules/ActiveInactiveSwitch";
import LocationFields from "../../components/form/molecules/LocationFields";
import ContactFields from "../../components/form/molecules/ContactFields";
import Modal from "../../components/Modal";
import ImagePreview from "../../components/form/molecules/ImagePreview";

import {
  MotifAccordion,
  MotifAccordionContent,
  MotifAccordionTrigger,
  MotifButton,
} from "@ey-xd/motif-react";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/css/pages/new-activity.scss";
import Comments from "../../components/form/molecules/Comments";
import {
  postAction,
  fetchCollectiveData,
  fetchOrganizationsData,
  fetchCategoriesData,
  fetchCountriesData,
} from "../../services/api";

import { fetchCities } from "../../services/api/countriesApi";

import LabelsContext from "../../context/LabelsContext";
import { handleFileUpload } from "../../services/UploadImageBlob";
import {
  imageUploadedFilter,
  nanoid,
  REQUIRED_FIELDS,
  isoDate,
  deleteEmptyFields,
  validateEmail,
} from "../../globals";
import InvalidModal from "../../components/InvalidModal";
import { format } from "date-fns";

const NewActivity = () => {
  const [showModal, setShowModal] = useState(false);
  const [showInvalidModal, setShowInvalidModal] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [collectiveOptions, setCollectiveOptions] = useState([]);
  const [organizationId, setOrganizations] = useState([]);
  const [categoryOptions, setCategories] = useState([]);
  const [fileQueue, setFileQueue] = useState([]);
  const [isFormSubmited, setisFormSubmited] = useState(false);
  const [actionType, setActionType] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isAttendanceActive, setIsAttendanceActive] = useState(true);
  const [missingFields, setMissingFields] = useState([]);
  const navigate = useNavigate();
  const labels = useContext(LabelsContext);

  const [formData, setFormData] = useState({
    active: true,
    title: "",
    subTitle: "",
    image: {},
    url: "",
    organizationId: "",
    collectiveIdList: [],
    categoryId: null,
    skills: "",
    description: "",
    startDate: null,
    endDate: null,
    totalVolunteerHours: "",
    points: null,
    deadLineDate: null,
    fiscalYear: "",
    requiredVolunteers: "",
    directBeneficiaries: "",
    donationToEntity: "",
    directCost: "",
    cost: "",
    initiative: null,
    engagementCode: "",
    maxParticipants: "",
    minParticipants: "",
    attendance: null,
    geographicArea: null,
    city: null,
    country: null,
    street: "",
    latitude: "",
    longitude: "",
    region: null,
    descriptionLocation: null,
    contactName: "",
    contactEmail: "",
    comments: "",
    thirdParty: "",
    startHour: new Date(),
    endHour: new Date(),
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCollectiveData().then((data) => {
      const options = Array.isArray(data.collectives)
        ? data.collectives.map((collective) => ({
            value: collective.id,
            label: collective.name,
          }))
        : [];
      setCollectiveOptions(options);
    });
    fetchOrganizationsData(false).then((data) => {
      const organizations = Array.isArray(data) ? data : [];
      setOrganizations(organizations);
    });
    fetchCategoriesData().then((data) => {
      const categories = Array.isArray(data.categories)
        ? data.categories.map((category) => ({
            value: category.id,
            label: category.name,
          }))
        : [];
      setCategories(categories);
    });

    fetchCountriesData()
      .then((data) => {
        const countries = Array.isArray(data)
          ? data.map((country) => ({
              value: country.countryName,
              label: country.countryName,
              key: country.countryKey,
            }))
          : [];
        setCountryOptions(countries);
      })
      .catch((error) => console.error("Error al cargar países:", error));
  }, []);
  const handleFieldChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };
  useEffect(() => {
    setIsAttendanceActive(formData.attendance !== "ONLINE");
  }, [formData.attendance]);
  const handleFormSubmit = async (drafted) => {
    setisFormSubmited(true);
    if (isFormValid()) {
      setIsDisabled(true);
      const actionId = nanoid();
      const coordinates =
        !formData.latitude ||
        !formData.longitude ||
        formData.latitude === "" ||
        formData.longitude === ""
          ? null
          : {
              latitude: parseFloat(formData.latitude),
              longitude: parseFloat(formData.longitude),
            };
      const address = {
        city: formData.city,
        country: formData.country,
        street: formData.street,
      };
      const contact = {
        name: formData.contactName,
        email: formData.contactEmail,
      };
      let copyFormData = { ...formData };
      delete copyFormData.latitude;
      delete copyFormData.longitude;
      delete copyFormData.contactEmail;
      delete copyFormData.contactName;
      delete copyFormData.city;
      delete copyFormData.country;
      delete copyFormData.street;
      delete copyFormData.geographicArea;
      delete copyFormData.region;
      const submitData = {
        ...copyFormData,
        startDate: formData.startDate ? isoDate(formData.startDate) : null,
        endDate: formData.endDate ? isoDate(formData.endDate) : null,
        subTitle: formData.subTitle ? formData.subTitle : null,

        startHour: formData.startHour
          ? format(
              new Date(
                formData.startHour?.getTime() +
                  formData.startHour?.getTimezoneOffset() * 60000
              ),
              "HH:mm"
            )
          : null,
        deadLineDate: formData.deadLineDate
          ? isoDate(
              formData.deadLineDate,
              format(formData.startHour, "HH:mm"),
              isoDate(formData.startDate),
              isoDate(formData.deadLineDate)
            )
          : null,
        endHour: formData.endHour
          ? format(
              new Date(
                formData.endHour?.getTime() +
                  formData.endHour?.getTimezoneOffset() * 60000
              ),
              "HH:mm"
            )
          : null,
        contact,
        points: formData.totalVolunteerHours * 100,
        location: {
          address,
          coordinates,
          region: formData.region,
          description: formData.descriptionLocation,
          geographicArea: formData.geographicArea,
        },
        video: formData.url
          ? { url: formData.url, mimeType: "image/jpeg" }
          : null,
        actionId,
        image: imageUploadedFilter(fileQueue, actionId, "action"),
        isDraft: drafted,
        pastAction: false,
        skills: formData.skills ? [formData.skills] : [],
        comments: formData.comments === "<p><br></p>" ? "" : formData.comments,
      };
      handleFileUpload(fileQueue, submitData.actionId, "action");
      await postAction(deleteEmptyFields(submitData));
      navigate("/actions/all-actions");
      setIsDisabled(false);
    } else {
      setShowInvalidModal(true);
      setIsDisabled(false);
    }
  };
  const isFormValid = () => {
    const requiredFields = [...REQUIRED_FIELDS.activities];
    if (formData.initiative === "OTHER" && formData.thirdParty === "") {
      requiredFields.push("thirdParty");
    }
    const newMissingFields = [];

    if (formData.description === "<p><br></p>") {
      newMissingFields.push("description");
      setMissingFields(newMissingFields);
      return false;
    }
    if (!validateEmail(formData.contactEmail)) {
      newMissingFields.push("contactEmail");
      setMissingFields(newMissingFields);
      return false;
    }
    if (formData.attendance === "ONLINE") {
      const fieldsToRemove = [
        "geographicArea",
        "region",
        "city",
        "latitude",
        "longitude",
        "street",
      ];
      const filteredMissingFields = newMissingFields.filter(
        (field) => !fieldsToRemove.includes(field)
      );
      setMissingFields(filteredMissingFields);
      return filteredMissingFields.length === 0;
    }
    const invalidFields = requiredFields.filter((field) => {
      if (field === "comments") return false;
      return !formData[field];
    });
    newMissingFields.push(...invalidFields);
    setMissingFields(newMissingFields);
    if (invalidFields.length > 0) {
      return false;
    }
    return true;
  };

  //MODAL
  const openWarningModal = () => {
    setActionType("save");
    setShowModal(true);
  };

  const closeWarningModal = () => {
    setShowModal(false);
  };
  const closeInvalidModal = () => {
    setShowModal(false);
    setShowInvalidModal(false);
  };
  const handleCancelClick = () => {
    setActionType("cancel");
    setShowModal(true);
  };
  const handleDiscardChanges = () => {
    window.scrollTo(0, 0);
    setShowModal(false);
    navigate("/");
  };
  const handleNavigateHome = () => {
    navigate("/actions/all-actions");
  };

  const handleActiveChange = (isActive) => {
    setFormData({ ...formData, active: isActive });
  };

  const handleCountryChange = (countryCode) => {
    fetchCities(countryCode)
      .then((data) => {
        setCityOptions(
          data.geonames.map((city) => ({ value: city.name, label: city.name }))
        );
      })
      .catch((error) =>
        console.error(
          `Error al obtener ciudades para el país ${countryCode}:`,
          error
        )
      );
  };

  const switchLabels = {
    active: labels.console_show_action,
    inactive: labels.console_hide_action,
  };

  const formFieldConfig = {
    latitude: { required: true },
    longitude: { required: true },
    city: { required: true },
    country: { required: true },
    street: { required: true },
    image: { required: true },
  };
  return (
    <>
      <SecondaryMenu
        title={labels.console_create_new_action}
        onNavigate={handleNavigateHome}
        editing={true}
        sendFormDraft={() => handleFormSubmit(true)}
        pageType="activities"
      />
      <section className="motif-col-lg-12 section-page">
        <div className={fileQueue[0] ? "container-test" : null}>
          <div>
            <SectionFields
              formData={formData}
              setFormData={setFormData}
              isFormSubmited={isFormSubmited}
              setFileQueue={setFileQueue}
              collectiveOptions={collectiveOptions}
              organizationId={organizationId}
              categoryOptions={categoryOptions}
              descriptionLabel={labels.console_description}
              imageLabel={labels.console_image}
              formFieldConfig={formFieldConfig}
              mode={"create"}
              fieldVisibility={{
                image: true,
                url: true,
                collectiveIdList: true,
                categoryId: true,
                donationType: false,
                organizationId: true,
                description: true,
                title: true,
                subtitle: true,
                skills: true,
              }}
            />
          </div>
          {fileQueue[0] && (
            <img src={fileQueue[0]?.previewUrl} alt="" className="image-test" />
          )}
        </div>
        <MotifAccordion alignIconRight useChevronIcon className="accordion">
          <MotifAccordionTrigger className="accordion-title">
            {labels.console_dateTime}
          </MotifAccordionTrigger>
          <MotifAccordionContent className="accordion-box">
            <DateTimeForm
              formData={formData}
              setFormData={setFormData}
              isFormSubmited={isFormSubmited}
              fieldVisibility={{
                startDate: true,
                endDate: true,
                startHour: true,
                endHour: true,
                totalVolunteerHours: true,
              }}
            />
          </MotifAccordionContent>
        </MotifAccordion>
        <MotifAccordion alignIconRight useChevronIcon className="accordion">
          <MotifAccordionTrigger className="accordion-title">
            {labels.console_date_management_details}
          </MotifAccordionTrigger>
          <MotifAccordionContent className="accordion-box">
            <ManagementDetailsFields
              onFieldChange={handleFieldChange}
              formData={formData}
              setFormData={setFormData}
              isFormSubmited={isFormSubmited}
              isPreview={false}
              pageType="activities"
              fieldVisibility={{
                points: true,
                deadLineDate: true,
                fiscalYear: true,
                attendance: true,
                requiredVolunteers: true,
                maxParticipants: true,
                minParticipants: true,
                directBeneficiaries: true,
                indirectBeneficiaries: false,
                donationToEntity: true,
                directCost: true,
                initiative: true,
                engagementCode: true,
                cost: true,
                thirdParty: true,
              }}
            />
          </MotifAccordionContent>
        </MotifAccordion>
        <MotifAccordion alignIconRight useChevronIcon className="accordion">
          <MotifAccordionTrigger className="accordion-title">
            {labels.console_location}
          </MotifAccordionTrigger>
          <MotifAccordionContent className="accordion-box">
            <LocationFields
              onFieldChange={handleFieldChange}
              formData={formData}
              setFormData={setFormData}
              isFormSubmited={isFormSubmited}
              countryOptions={countryOptions}
              cityOptions={cityOptions}
              onCountryChange={handleCountryChange}
              setCityOptions={setCityOptions}
              formFieldConfig={formFieldConfig}
              fieldVisibility={{
                geographicArea: isAttendanceActive,
                region: isAttendanceActive,
                country: true,
                city: isAttendanceActive,
                latitude: isAttendanceActive,
                longitude: isAttendanceActive,
                street: isAttendanceActive,
              }}
            />
          </MotifAccordionContent>
        </MotifAccordion>
        <MotifAccordion alignIconRight useChevronIcon className="accordion">
          <MotifAccordionTrigger className="accordion-title">
            {labels.console_pointOfContact}
          </MotifAccordionTrigger>
          <MotifAccordionContent className="accordion-box">
            <ContactFields
              onFieldChange={handleFieldChange}
              formData={formData}
              setFormData={setFormData}
              isFormSubmited={isFormSubmited}
              labels={labels}
              fieldVisibility={{
                contactName: true,
                contactEmail: true,
                rolOrg: false,
                phoneNumber: false,
              }}
            />
          </MotifAccordionContent>
        </MotifAccordion>
        <MotifAccordion alignIconRight useChevronIcon className="accordion">
          <MotifAccordionTrigger className="accordion-title">
            {labels.console_comments}
          </MotifAccordionTrigger>
          <MotifAccordionContent className="accordion-box">
            <Comments
              onFieldChange={handleFieldChange}
              formData={formData}
              setFormData={setFormData}
              isFormSubmited={isFormSubmited}
            />
          </MotifAccordionContent>
        </MotifAccordion>
        <ActiveInactiveSwitch
          labels={switchLabels}
          isActive={formData.active}
          onChange={handleActiveChange}
          className={"inactive-switch"}
        />
        <div className="activity-buttons">
          <MotifButton
            variant="secondary"
            onClick={handleCancelClick}
            className="activity-buttons-secondary"
          >
            {labels.console_cancel}
          </MotifButton>
          <MotifButton
            onClick={openWarningModal}
            className="activity-buttons-primary"
          >
            {labels.console_save}
          </MotifButton>
        </div>
      </section>
      <section className="motif-col-lg-5">
        <ImagePreview imageQueue={fileQueue} />
      </section>

      <Modal
        show={showModal}
        onClose={closeWarningModal}
        title={
          actionType === "save"
            ? labels.console_changes_saved
            : labels.console_unsavedChanges
        }
        body={
          actionType === "save"
            ? labels.console_confirm_publish
            : labels.console_leavePage
        }
        primaryButtonText={
          actionType === "save"
            ? labels.console_publish
            : labels.console_keepEditing
        }
        onPrimaryButtonClick={
          actionType === "save"
            ? () => handleFormSubmit(false)
            : closeWarningModal
        }
        secondaryButtonText={
          actionType === "save"
            ? labels.console_cancel
            : labels.console_discardChanges
        }
        onSecondaryButtonClick={handleDiscardChanges}
        disabled={isDisabled}
      />
      <InvalidModal
        show={showInvalidModal}
        onClose={closeInvalidModal}
        title={labels.console_title_invalidForm}
        body={labels.console_body_invalidForm}
        primaryButtonText={labels.console_close}
        onPrimaryButtonClick={closeInvalidModal}
        invalidFieldsMessage={missingFields.join(", ")}
      />
    </>
  );
};
export default NewActivity;
